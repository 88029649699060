<template>
  <div>
    <el-dialog class="tw-table-column-setting-dialog" :visible="visible" fullscreen @update:visible="updateVisibility">
      <div class="settings-wrapper">
        <el-transfer v-model="valueForTransfer" :data="dataForTransfer" :titles="titles" target-order="push" @left-check-change="dummyUpdateForLeftItems" @right-check-change="updateChangeableOrderItemKeys">
        </el-transfer>
        <div class="order-button-wrapper">
          <el-button type="primary" icon="el-icon-arrow-up" :disabled="changeableOrderItemKeys.length === 0" @click="changeOrder('up')"></el-button>
          <el-button type="primary" icon="el-icon-arrow-down" :disabled="changeableOrderItemKeys.length === 0" @click="changeOrder('down')"></el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    titlesKeys: {
      type: Array,
      required: false,
      default: () => ['Hidden', 'Visible']
    },
    fullSchemas: {
      type: Array,
      required: true
    },
    showSchemas: {
      type: Array,
      required: true
    },
    schemaKey: {
      type: String,
      required: false,
      default: 'key'
    },
    schemaLabel: {
      type: String,
      required: false,
      default: 'label'
    }
  },
  data() {
    return {
      leftItemKeys: [],
      changeableOrderItemKeys: [],
    }
  },
  computed: {
    valueForTransfer: {
      get() {
        return this.showSchemas.map(schema => schema[this.schemaKey])
      },
      set(newVal) {
        const result = [];
        for(const val of newVal) {
          const target = this.showSchemas.find(schema => schema[this.schemaKey] === val) || this.fullSchemas.find(schema => schema[this.schemaKey] === val);
          result.push(target);
        }
        this.changeableOrderItemKeys = this.changeableOrderItemKeys.filter(key => newVal.includes(key));
        this.$emit('update:showSchemas', result);
      }
    },
    dataForTransfer() {
      return this.fullSchemas.map((schema) => ({ key: schema[this.schemaKey], label: this.$t(`List.${schema[this.schemaLabel]}`) }));
    },
    titles() {
      return [
        this.$t(`Label.${this.titlesKeys[0]}`),
        this.$t(`Label.${this.titlesKeys[1]}`)
      ]
    }
  },
  methods: {
    updateVisibility(isVisible) {
      if(!isVisible) {
        this.$emit('update:visible', isVisible);
        this.$emit('close', _.cloneDeep(this.showSchemas));
        this.changeableOrderItemKeys.length = 0;
        this.leftItemKeys.length = 0;
      }
    },
    updateChangeableOrderItemKeys(checkedItemKeys) {
      this.changeableOrderItemKeys = checkedItemKeys;
    },
    // visibilityを変更した際にチェックリスト（左）をリセットできるようにするためのダミーメソッド
    dummyUpdateForLeftItems(keys) {
      this.leftItemKeys = keys;
    },
    changeOrder(direction) {
      const result = _.cloneDeep(this.showSchemas);
      // 追加順ではなく、並び順に合わせてチェックの付いているkeyの順番をsortする
      this.changeableOrderItemKeys = this.changeableOrderItemKeys.sort((a, b) => {
        const indexA = result.findIndex(schema => schema[this.schemaKey] === a);
        const indexB = result.findIndex(schema => schema[this.schemaKey] === b);
        return indexA > indexB ? 1 : -1;
      })
      if(direction === 'down') this.changeableOrderItemKeys = this.changeableOrderItemKeys.reverse();
      
      let unmovableIndex = direction === 'down' ? result.length - 1 : 0;
      for(const itemKey of this.changeableOrderItemKeys) {
        const targetIndex = result.findIndex(schema => schema[this.schemaKey] === itemKey);
        if(targetIndex === unmovableIndex) unmovableIndex = direction === 'down' ? unmovableIndex - 1 : unmovableIndex + 1; 
        else {
          if(direction === 'down') result.splice(targetIndex, 2, result[targetIndex + 1], result[targetIndex]);
          else result.splice(targetIndex - 1, 2, result[targetIndex], result[targetIndex - 1]);
        }
      }
      this.$emit('update:showSchemas', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.tw-table-column-setting-dialog {
  &::v-deep .el-dialog {
    overflow-x: hidden;
    & .el-dialog__header {
      padding-top: 32px;
      margin: 0 -24px;
      background-color: $color_dark_blue;
      
      & .el-dialog__headerbtn .el-dialog__close {
        color: $color_white;
        &:hover {
          color: $color_gray_200;
        }
      }
    }
  }
}
// サイズは親コンポーネントから上書きして使用する
.settings-wrapper {
  width: 100vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
  & ::v-deep .el-transfer-panel {
    width: 300px;
    & .el-transfer-panel__body, & .el-transfer-panel__body .el-transfer-panel__list {
      height: 500px;
      width: 300px;
    }
  }
  & .order-button-wrapper {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .el-button {
      margin-top: 8px;
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>