import _ from 'lodash';
import TwTableColumnSettingDialog from '@/components/organisms/TwTableColumnSettingDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    TwTableColumnSettingDialog
  },
  data() {
    return {
      // 元となる「schemas」は呼び出しもとで定義すること
      tableMode: 'show',
      filteredSchemas: [],
    }
  },
  computed: {
    ...mapGetters(['getTableSetting']),
    showSchema() {
      return this.filteredSchemas.map(schema => {
        return {
          ...schema,
          sortable: this.tableMode === 'changeWidth' ? false : schema.sortable
        };
      });
    },
  },
  created() {
    if (this.tableId === undefined) return;
    this.filteredSchemas = _.cloneDeep(this.getTableSetting(this.tableId)?.schemas || _.cloneDeep(this.schemas.filter(schema => schema.column === 'default' )));
  },
  methods: {
    changeWidthMode() {
      this.tableMode = this.tableMode === 'changeWidth' ? 'show' : 'changeWidth';
    },
    changeColumnWidth({ newWidth, column }) {
      const index = this.filteredSchemas.findIndex(schema => schema.key === column.property);
      this.filteredSchemas[index].width = newWidth;
      this.setTableSetting();
    },
    async updateColumnSetting(items) {
      this.filteredSchemas.splice(0, this.filteredSchemas.length);
      await this.$nextTick();
      this.filteredSchemas = [...items];
      this.setTableSetting();
    },
    async resetTableSetting() {
      this.filteredSchemas.splice(0, this.filteredSchemas.length);
      await this.$nextTick();
      this.filteredSchemas = _.cloneDeep(this.schemas.filter(schema => schema.column === 'default' ));
      this.setTableSetting();
    },
    setTableSetting() {
      if(this.tableId === undefined) return;
      this.$store.commit('SET_TABLE_SETTING', { tableId: this.tableId, tableSetting: { schemas: this.filteredSchemas } });
    }
  }
}